export default {
  CidadesBuscarPorCep: "cidades/cep/{cep}",
  CidadesBuscarPorUF: "estados/{uf}",
  EstadosBusca: "estados/",
  EmpresasBusca: "empresas/",
  EmpresasId: (idEmpresa) => `empresas/id/${idEmpresa}`,
  EmpresaResponsavel: (idEmpresa) =>
    `empresas/${idEmpresa}/contato/responsavel`,
  EmpresaBuscaFiliais: (idEmpresaGrupo) => `/filiais/grupo/${idEmpresaGrupo}`,
  EmpresasBuscarPorCnpj: "empresas/cnpj/{cnpj}",
  EmpresasValidarCnpj: (cnpj) => `empresas/consultar-cnpj/${cnpj}`,
  RegionaisBusca: "regioes/",
  ModulosBusca: "modulos/",
  ModulosProdutos: "modulos/ciclo",
  PerfisBusca: "perfis/",
  CadastroEmpresa: "empresas/",
  AtualizaEmpresa: "empresas/{id}",
  DadosEmpresa: "empresas/{id}",
  GeneroBusca: "generos/",

  GruposBusca: "grupos/",
  GruposBuscaPorModulo: "grupos/modulo/{idModulo}",
  GrupoDetalhe: "grupos/{grupo}/relacionamento/{relacionamento}",
  ResponsavelEmpresa: "empresas/{empresa}/responsavel/",
  EmpresaBuscarGrupoTipoPorId: "empresas/{id}/grupo-tipo",

  CadastroPessoa: "pessoa/",
  UpdatePessoa: "pessoa/{idColaborador}",

  BuscaDadosPessoa: "dados-colaborador/{colaborador}",
  BuscaPessoaCpf: "pessoa-por-cpf/{cpf}",
  ColaboradoresGrupo: "empresa-grupo/colaboradores",
  ColaboradoresEmpresaCiclo: "empresa/ciclo/colaboradores",
  PerfilBusca: "perfis/",
  EstadoCivilBusca: "estado-civil/",
  EscolaridadeBusca: "escolaridades/",
  RegimeBusca: "regimes/",
  TurnoBusca: "empresa-grupo/{grupo}/turnos-ativos",
  FuncaoBusca: "empresa-grupo/{grupo}/funcoes-ativas",
  SetorBusca: "empresa-grupo/{grupo}/setores-ativos",
  GerenciasBusca: "empresa-grupo/{grupo}/gerencias-ativas",
  DiretoriasBusca: "empresa-grupo/{grupo}/diretorias-ativas",
  UnidadeBusca: "empresa-grupo/{grupo}/unidades-ativas",
  EmpresaBusca: "empresas/grupo/{grupo}",
  LiderBusca: "empresa-grupo/{grupo}/empresa/{empresa}/lideres",
  BuscaPerfisColab: "perfis-colaborador/colaborador/{colaborador}",
  AlteraLoteColaboradorLider: "empresa-grupo/colaboradores/lider",

  BuscaParametrosTurno: "turnos/",
  BuscaVinculoTurno: "vinculo-turno/empresa-turno/{idEmpresaTurno}",
  EmpresaTurnoSemVinculo: "empresa-turno-sem-vinculo/empresa-grupo/{grupo}",
  CadastroEmpresaTurno: "empresa-turno/",
  UpdateEmpresaTurno: "/empresa-turno/{idEmpresaTurno}",
  BuscaEmpresaTurno: "empresa-turno/empresa-grupo/{grupo}",
  DeletaEmpresaTurno: "/empresa-turno/{idEmpresaTurno}",

  BuscaParametrosFuncao: "funcoes/",
  BuscaVinculoFuncao: "vinculo-funcao/empresa-funcao/{idEmpresaFuncao}",
  EmpresaFuncaoSemVinculo: "empresa-funcao-sem-vinculo/empresa-grupo/{grupo}",
  CadastroEmpresaFuncao: "empresa-funcao/",
  UpdateEmpresaFuncao: "/empresa-funcao/{idEmpresaFuncao}",
  BuscaEmpresaFuncao: "empresa-funcao/empresa-grupo/{grupo}",
  DeletaEmpresaFuncao: "/empresa-funcao/{idEmpresaFuncao}",

  CadastroEmpresaSetor: "empresa-setor/",
  UpdateEmpresaSetor: "/empresa-setor/{idEmpresaSetor}",
  BuscaEmpresaSetor: "empresa-setor/empresa-grupo/{grupo}",
  DeletaEmpresaSetor: "/empresa-setor/{idEmpresaSetor}",

  CadastroEmpresaUnidade: "empresa-unidade/",
  UpdateEmpresaUnidade: "/empresa-unidade/{idEmpresaUnidade}",
  BuscaEmpresaUnidade: "empresa-unidade/empresa-grupo/{grupo}",
  DeletaEmpresaUnidade: "/empresa-unidade/{idEmpresaUnidade}",

  CadastroEmpresaDiretoria: "empresa-diretoria/",
  UpdateEmpresaDiretoria: "/empresa-diretoria/{idEmpresaDiretoria}",
  BuscaEmpresaDiretoria: "empresa-diretoria/empresa-grupo/{grupo}",
  DeletaEmpresaDiretoria: "/empresa-diretoria/{idEmpresaDiretoria}",

  CadastroEmpresaGerencia: "empresa-gerencia/",
  UpdateEmpresaGerencia: "/empresa-gerencia/{idEmpresaGerencia}",
  BuscaEmpresaGerencia: "empresa-gerencia/empresa-grupo/{grupo}",
  DeletaEmpresaGerencia: "/empresa-gerencia/{idEmpresaGerencia}",

  ImportarPlanilhaCiclos: "integracao/ciclos",
  ImportarPlanilhaColaboradores: "integracao/colaboradores",
  ImportarPlanilhaUsuarios: "integracao/usuarios",
  Login: "auth/login",
  LoginAdmin: "auth/login/admin",
  Logout: "auth/logout",
  EsqueceuSenha: "password/forgot",
  RedefinirSenha: "password/reset",
  RedefinirSenhaEmpresa: "password/redefinirUserEmpresa",

  TipoNotificacao: "notificacoes/tipo-notificacoes",
  FormaNotificacao: "notificacoes/forma-notificacoes",
  NotificacoesUsuario: "notificacoes-usuario",
  InsereNotificacoes: "notificacoes",
  FormaNotificacaoTipoNotificacao:
    "notificacoes/forma-notificacaoes-tipo-notificacoes",

  DashboardPlanejaCiclos: "/dashboard/planeja-saude/ciclos/empresa/{idEmpresa}",
  DashboardPlanejaResumo: "/dashboard/planeja-saude/cards-resumo",
  DashboardPlanejaClassificacaoSaude:
    "/dashboard/planeja-saude/classificacao-saude",
  DashboardPlanejaDoencasCronicasTotal:
    "/dashboard/planeja-saude/doencas-total",
  DashboardPlanejaDoencasCronicas: "/dashboard/planeja-saude/doenca",
  DashboardPlanejaHabitos: "/dashboard/planeja-saude/habito",
  DashboardPlanejaCardTaxaRespostaEquipe:
    "/dashboard/planeja-saude/taxa-de-resposta-por-equipe",

  QuestionariosPorEmpresa:
    "/questionario/select",
  QuestionariosPorEmpresaECiclos:
    "/questionario/select/ciclos",
  DashboardSaudeMentalCiclos: 
    "/dashboard/saude-mental/phq-9/fases/empresa/{idEmpresa}",
  DashboardSaudeMentalCardAreaMaiorDificuldade:
    "/dashboard/saude-mental/phq-9/areas-de-maior-dificuldade",
  DashboardSaudeMentalCardTaxaRespostaEquipe:
    "/dashboard/saude-mental/phq-9/taxa-de-resposta-por-equipe",
  DashboardSaudeMentalCardEstatisticasNiveisDepressao:
    "/dashboard/saude-mental/phq-9/estatisticas-niveis-depressao",
  DashboardSaudeMentalCardRespostasEscalaTotal:
    "/dashboard/saude-mental/phq-9/respostas-escala-total",
  DashboardSaudeMentalCardRespostasEscalaSetor:
    "/dashboard/saude-mental/phq-9/respostas-escala-setor",
  DashboardSaudeMentalCardRespostasEscalaGerencia:
    "/dashboard/saude-mental/phq-9/respostas-escala-gerencia",
  DashboardSaudeMentalCardRespostasEscalaFaixaEtaria:
    "/dashboard/saude-mental/phq-9/respostas-escala-faixa-etaria",
  DashboardSaudeMentalCardRespostasEscalaGenero:
    "/dashboard/saude-mental/phq-9/respostas-escala-genero",
  DashboardSaudeMentalCardRespostasEscalaLideres:
    "/dashboard/saude-mental/phq-9/respostas-escala-lideres",
  DashboardSaudeMentalCardMaiorDificuldadeTrabalho:
    "/dashboard/saude-mental/phq-9/maior-dificuldade-trabalho",
  VerificaNovaPerguntaSaudeMental:
    "/dashboard/saude-mental/phq-9/verifica-nova-pergunta",
  DashboarbSaudeMentalCardIndicacaos: "/dashboard/saude-mental/phq-9/consulta-indicacao",
  RetornaEmpresaGrupo: "/retorna-empresa-grupo/{idEmpresa}",
  VerificaQuestionariosSaudeMental: (idEmpresa, idEmpresaGrupo) => `/verifica-dados-questionarios-saude-mental/${idEmpresa}/${idEmpresaGrupo}`,


  EmpresaBuscarDashboard: "/empresas/consultar-dashboard/modulo/{modulo}",

  FaqAjudas: "/ajudas",
  FaqAjudasEnviarEmailDuvida: "/ajuda/enviar-email-duvida",

  IntegracaoPlanilhaBuscarPorIdEmpresaGrupo:
    "/integracao/planilhas/grupo/{idEmpresaGrupo}",
  IntegracaoPlanilhaExportar: "/integracao/planilhas/exportar/{idPlanilha}",

  DadosMinhaConta: "dados-minha-conta",

  MeusDados: "meus-dados",

  RegrasUsuarioLogado: "/regras-usuario",

  BroadcastingAuth: "/broadcasting/auth",
  MinhasNotificacoesPendentes: "/notificacoes/minhas-notificacaoes/pendentes",
  MinhasNotificacoesAtualizar:
    "/notificacoes/minhas-notificacaoes/notificacao-usuario/{notificacaoUsuario}",
  AtualizarNotificacaoPorAcompanhamento:
    "/notificacoes/acompanhamento/{acompanhamento}",

  EmpresasBuscarPorMatriz: "empresas/buscar/tipo/matriz",
  EmpresasBuscarPorFilial: "empresas/buscar/tipo/filial",

  AcompanhamentoBuscarCiclosPorIdEmpresa:
    "/acompanhamentos/ciclos/empresa/{idEmpresa}",
  AcompanhamentoRespostasDoCicloCompartilhadaComigo:
    "/acompanhamentos/acompanhamentos-opcoes/respostas-compartilhadas-comigo/fase/{fase}",
  AcompanhamentoBuscarFasesPorIdEmpresa:
    "/acompanhamentos/fases/empresa/{idEmpresa}",
  AcompanhamentoRespostasDoCicloCompartilhadaRiscoVida:
    "/acompanhamentos/acompanhamentos-opcoes/respostas-compartilhadas-risco-a-vida/fase/{fase}",

  UsuariosSesi: "usuarios/admin",
  UsuariosSesiAtualizar: (id) => `usuarios/admin/${id}`,

  ativarInativarEmpresa: (id) => `empresas/ativar-inativar/${id}`,
  AcompanhamentoBuscarRespostasPorIdAcompanhamento:
    "/acompanhamentos/{idAcompanhamento}/respostas",

  ativarInativarUsuarioAdmin: "usuarios/admin/status/ativar-inativar",
  InterverStatusAtivoUsuarioEmpresa: "usuarios/inverter-status/empresa",

  AjudaSuporte: "ajuda",
  AjudaCategoria: "ajuda/categoria",

  ObservacoesConsultarPorIdAcompanhamento:
    "acompanhamentos/{idAcompanhamento}/observacoes?protecao_vida={protecaoVida}",
  ObservacoesCadastrar:
    "acompanhamentos/{idAcompanhamento}/observacoes?protecao_vida={protecaoVida}",
  ObservacoesAtualizar:
    "acompanhamentos/{idAcompanhamento}/observacoes/{idAcompanhamentoObservacao}?protecao_vida={protecaoVida}",

  CiclosParticipantesDaEmpresa: "participantes-ciclo/empresa/{idEmpresa}/ciclo",
  CiclosParticipantesDaEmpresaEdicao: "participantes-ciclo-edicao/empresa/{idEmpresa}/ciclo",
  CiclosInsert: "ciclos",
  CiclosBusca: "ciclos",
  CicloShow: "ciclos/{idCiclo}",
  CicloUpdate: "ciclos/{idCiclo}",
  CicloCancelar: "ciclos/{idCiclo}/cancelar",
  FasesDisponiveis: "fases-disponiveis/empresa/{idEmpresa}/modulo/{idModulo}/questionario/{idQuestionario}",

  Modulo: "modulo",
  ModuloEmpresa: "empresa/modulo/empresa-grupo/{idEmpresaGrupo}",
  ModuloAlteraStatus: "modulo/alterar-status",

  QuestoesPersonalizadas:
    "/empresa-grupo/modulo/questao-mensagem/personalizada",

  UpdateMensagem:
    "/empresa-grupo/{idEmpresaGrupo}/mensagem/{idMensagem}/personalizar",
  UpdateQuestao:
    "/empresa-grupo/{idEmpresaGrupo}/questao/{idQuestao}/personalizar",
  ResetarQuestoesPersonalizadas:
    "/empresa-grupo/modulo/questao-mensagem/resetar",

  Documento: "documento",

  Fases: "acompanhamentos/fases/",

  PlanejaSaude: {
    retornaDadosRelatorioIndividual: (idFase, idColaborador) => `/planeja-saude/relatorio-dados/fase/${idFase}/colaborador/${idColaborador}`,
    downloadRelatorioIndividualPDF: (idFase, idColaborador) => `/planeja-saude/relatorio-download/fase/${idFase}/colaborador/${idColaborador}`,
    iniciaPreparacaoArquivoZipParaRelatorioEmMassaOuDownloadZip: (idFase, idEmpresa) => `/planeja-saude/relatorio-download/fase/${idFase}/empresa/${idEmpresa}`,
    baixarRelatorioAcompanhamento: (idEmpresa, idFase) => `planeja-saude/baixar-relatorio-acompanhamento/empresa/${idEmpresa}/fase/${idFase}`,
    gerarRelatorioAcompanhamento: (idEmpresa, idFase) => `planeja-saude/gerar-relatorio-acompanhamento/empresa/${idEmpresa}/fase/${idFase}`,
    listarUltimosRelatorios: "/planeja-saude/relatorios/relatorio-acompanhamento/listar",
  },

  OutrosProdutos: {
    getAndSaveProduct: "produto",
    changeProductRelevance: "produto/alterar-relevancia",
    getAndDeleteAndChangeStateUniqueProduct: (idProduct) =>
      `produto/${idProduct}`,
    editUniqueProduct: (idProduct) => `produto/${idProduct}?_method=PUT`,
  },
  PessoaVisualizada: () => "auditoria/pessoa-visualizada",

  BuscaEnderecoCep: (cep) => `/cep/${cep}`,
  BuscaCidadesUF: (uf) => `/cidades/uf/${uf}`,

  cadastroEmpresaMatriz: "/empresas/matriz",
  cadastroEmpresaFilial: "/empresas/filial",

  MiniDashboardsPainelInicial: idEmpresa => `dashboard-inicial/${idEmpresa}`,

  edicaoEmpresaMatriz: (idEmpresa) => `/empresas/matriz/${idEmpresa}`,
  edicaoEmpresaFilial: (idEmpresa) => `/empresas/filial/${idEmpresa}`,

  buscaEmpresaUsuario: "/empresas/usuario",
  getReportsByModule: (idModulo) => `/power-bi/relatorios/${idModulo}`,
  accessPowerBiReport: (idRelatorioExternoAcessoValorUsuario, idModulo) => `/power-bi/acesso-relatorio/${idRelatorioExternoAcessoValorUsuario}/modulo/${idModulo}`,
  getCursosNR: `/cursos-nr/acesso-relatorio`,

  inserirNovaAplicacao: "/questionario/aplicacao/",

  buscaAplicacaoPorArquivoAnexo: '/questionario/aplicacao/dashboard/busca-aplicacao-por-arquivo-anexo',



  BuscaQuestionariosPorModulo: (idModulo) => `questionario/modulo/${idModulo}`,

  cora: {
    aplicacoes: '/questionario/aplicacao',
    alteraStatusAplicacao: '/questionario/aplicacao/alterar-status',
    inserirNovaAplicacao: "/questionario/aplicacao/inserir-aplicacao",
    aplicacaoShowAndEdit: (idAplicacao) => `questionario/aplicacao/${idAplicacao}`,
  },

  enviarEmailAplicacao: "/questionario/aplicacao/enviar-email",

  exportarPdf: "/questionario/aplicacao/exportar-pdf/{idAplicacao}",
  exportarQrcode: "/questionario/aplicacao/exportar-qrcode/{idAplicacao}",
  getRespostasAplicacao: "questionario/aplicacao/exportar-respostas/{idAplicacao}",
  downloadRespostasAplicacao: "questionario/aplicacao/download-respostas/{idAplicacao}",
  dataUltimoRelatorio: "questionario/aplicacao/data-ultimo-relatorio/{idAplicacao}",

  questionario: {
    inserir: '/questionario',
    inserirRascunho: '/questionario/true',
    atualizar: '/questionario/update',
    questionarios: '/questionario',
    atualizaStatus: '/questionario/{idQuestionario}/status/{novoStatus}',
    exportarRespostasPdf: 'questionario/questoes/gerar-pdf-questionario/',
    deletar: '/questionario/{idQuestionario}',
    retornaQuestionarioPorId: '/questionario/questionario/{idQuestionario}',
    duplicar: '/questionario/novo-questionario/duplicar/{idQuestionario}',
    verificaNomeExiste: '/questionario/verifica-nome/{nomeQuestionario}',
    verificaNomeExisteComId: '/questionario/verifica-nome/{nomeQuestionario}/id-questionario/{idQuestionario}',
    listarQuestionariosAtivosPorModulo: '/questionario/ativos/modulo/{idModulo}'
  },

  buscaFasesDisponiveisDashboardSaudeMentalDass21PorEmpresa: "/dashboard/saude-mental/dass-21/fases/{idEmpresa}", 
  buscaFasesDisponiveisDashboardSaudeMentalPhqPorEmpresa: "/dashboard/saude-mental/phq-9/fases/{idEmpresa}",
  buscaDadosDashboardSaudeMentalDass21PorFaseEEmpresa: "/dashboard/saude-mental/dass-21/conjunto-dados",
  buscaDadosCardRespostasPorGeneroDashboardSaudeMentalDass21PorFaseEEmpresa: "/dashboard/saude-mental/dass-21/respostas-por-genero",
  buscaDadosCardDadosGeraisDashboardSaudeMentalDass21PorFaseEEmpresa: "/dashboard/saude-mental/dass-21/dados-gerais",
  buscaDadosCardDetalhamentoPorAreaTotalDashboardSaudeMentalDass21PorFaseEEmpresa: "/dashboard/saude-mental/dass-21/detalhamento-por-area-total",
  buscaDadosCardDetalhamentoPorAreaSetorDashboardSaudeMentalDass21PorFaseEEmpresa: "/dashboard/saude-mental/dass-21/detalhamento-por-area-setor",
  buscaDadosCardDetalhamentoPorAreaGerenciaDashboardSaudeMentalDass21PorFaseEEmpresa: "/dashboard/saude-mental/dass-21/detalhamento-por-area-gerencia",
  buscaDadosCardDetalhamentoPorAreaLiderDashboardSaudeMentalDass21PorFaseEEmpresa: "/dashboard/saude-mental/dass-21/detalhamento-por-area-lider"
}
