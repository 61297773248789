import { $regraEntidade } from '@/custom-enum/regras-enum.js'

export default [
    {
        path: '/login',
        name: 'auth-login',
        component: () =>
            import ('@/views/pages/authentication/Login.vue'),
        meta: {
            layout: 'full',
            resource: $regraEntidade.rotas.autenticacao,
            title: 'Login'
        }
    },
    {
        path: '/login/admin',
        name: 'auth-login-admin',
        component: () =>
            import ('@/views/pages/authentication/LoginAdmin.vue'),
        meta: {
            layout: 'full',
            resource: $regraEntidade.rotas.autenticacao,
            title: 'Login SESI'
        }
    },
    {
        path: '/auth/senha-esquecida/',
        name: 'auth-forgot-password',
        component: () =>
            import ('@/views/pages/authentication/ForgotPassword.vue'),
        props: true,
        meta: {
            layout: 'full',
            resource: $regraEntidade.rotas.autenticacao,
            title: 'Esqueci minha senha'
        }
    },
    {
        path: '/auth/verificacao-email/:email',
        name: 'auth-verification-email',
        component: () =>
            import ('@/views/pages/authentication/VerificationEmail.vue'),
        props: true,
        meta: {
            layout: 'full',
            resource: $regraEntidade.rotas.autenticacao,
            title: 'Verificação de email'
        }
    },
    {
        path: '/auth/redefinicao-senha/:codigo',
        name: 'auth-reset-password',
        component: () =>
            import ('@/views/pages/authentication/ResetPassword.vue'),
        props: true,
        meta: {
            layout: 'full',
            resource: $regraEntidade.rotas.autenticacao,
            title: 'Redefinir senha'
        }
    }
]
