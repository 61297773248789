const generateUpdatedAt = () => Math.random();
import helpers from "@/helpers";
import api from "@/router/rotasAPI";
import axios from "@/libs/axios";

import { TIPONOTIFICACAO } from "@/custom-enum/tipo-notificacao.js";

const getRouteUpdateUserNotificationAsRead = (idNotificacaoUsuario) => {
    return helpers.preparaRotaComParametros(
        api.MinhasNotificacoesAtualizar,
        "{notificacaoUsuario}",
        idNotificacaoUsuario
    );
};

const consultaMinhasNotificacoes = (estado) => {
    axios.get(api.MinhasNotificacoesPendentes).then((res) => {
        estado.notifications.itens = res.status == 204 ? [] : res.data;
        estado.notifications.updated_at = generateUpdatedAt();
    });
};

const getNotificationsFiltered = (
    notifications,
    idTipoNotificacao = null,
    idTipoNotificacaoExclude = []

) => {

    let itens = notifications.itens != null ? notifications.itens : [];
    if (idTipoNotificacao != null) {
        itens = itens.filter(
            (notification) => notification.id_tipo_notificacao == idTipoNotificacao
        );
    }

    if (idTipoNotificacaoExclude.length > 0 && itens != null) {
        idTipoNotificacaoExclude.forEach((idNotificacao) => {
            itens = itens.filter(
                (notification) => notification.id_tipo_notificacao != idNotificacao
            );
        });
    }

    return {
        ...notifications,
        ... { itens },
    };
};

const state = {
    notifications: {
        user_id: null,
        itens: [],
        updated_at: null,
    },
};

const mutations = {
    RESET_STATE(estado) {
        estado.notifications = {
            user_id: null,
            itens: [],
        };
    },
    SET_UPDATED_AT(estado) {
        estado.notifications.updated_at = generateUpdatedAt();
    },
    SET_USER_ID(estado, user_id) {
        estado.notifications.user_id = user_id;
    },
    SET_ITENS(estado, itens) {
        estado.notifications.itens = itens;
    },
    UPDATE_USER_NOTIFICATION_AS_READ(estado, idNotificacaoUsuario) {
        let route = getRouteUpdateUserNotificationAsRead(idNotificacaoUsuario);
        axios
            .put(route, {
                visualizada: true,
            })
            .then((_res) => {
                consultaMinhasNotificacoes(estado);
            });
    },
};

const actions = {
    resetState({ commit }) {
        commit("RESET_STATE");
        commit("SET_UPDATED_AT");
    },
    setUserId({ commit }, user_id) {
        commit("SET_USER_ID", user_id);
        commit("SET_ITENS", null);
        commit("SET_UPDATED_AT");
    },
    setItens({ commit }, itens) {
        commit("SET_ITENS", itens);
        commit("SET_UPDATED_AT");
    },
    updateUserNotificationAsRead({ commit }, { idNotificacaoUsuario }) {
        commit("UPDATE_USER_NOTIFICATION_AS_READ", idNotificacaoUsuario);
        commit("SET_UPDATED_AT");
    },
};

const getters = {
    getUpdatedAt: (estado) => {
        return estado.notifications.updated_at;
    },
    getNotifications: (estado) => {
        return getNotificationsFiltered(estado.notifications);
    },
    getNotificationsImportacaoDePlanilha: (estado) => {
        return getNotificationsFiltered(
            estado.notifications,
            TIPONOTIFICACAO.IMPORTACAO_DE_PLANILHA
        );
    },
    getNotificationsAlertaDeRiscoAVida: (estado) => {
        return getNotificationsFiltered(
            estado.notifications,
            TIPONOTIFICACAO.ALERTA_DE_RISCO_A_VIDA
        );
    },
    getNotificationsProximidadeDeConclusaoDeCiclo: (estado) => {
        return getNotificationsFiltered(
            estado.notifications,
            TIPONOTIFICACAO.PROXIMIDADE_DE_CONCLUSAO_DE_CICLO
        );
    },
    getNotificationsTerminoDeCiclo: (estado) => {
        return getNotificationsFiltered(
            estado.notifications,
            TIPONOTIFICACAO.TERMINO_DE_CICLO
        );
    },
    getNotificationsEncerramentoDoQuestionario: (estado) => {
        return getNotificationsFiltered(
            estado.notifications,
            TIPONOTIFICACAO.ENCERRAMENTO_DO_QUESTIONARIO
        );
    },
    getNotificationsRespostasCompartilhadas: (estado) => {
        return getNotificationsFiltered(
            estado.notifications,
            TIPONOTIFICACAO.RESPOSTAS_COMPARTILHADAS
        );
    },
    getNotificationsBell: (estado) => {
        let notificacaoTipoModal = [
        ]
        return getNotificationsFiltered(estado.notifications, null, notificacaoTipoModal);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
