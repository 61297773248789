<template><div id="socketio"></div></template>

<script>
import SocketIO from "@/socketio";
import { isUserLoggedIn, getUserData, setAbility } from "@/auth/utils";

export default {
  data() {
    return {
      userLogged: null,
      SocketIOConnectError: "connect_error",
      SocketIOPrivateMessage: "private message",
    };
  },
  beforeMount() {
    if(this.layoutIsNotCora()){
      if (isUserLoggedIn()) {
        this.userLogged = {
          id_usuario: getUserData().usuario.id_usuario,
          email: getUserData().usuario.email,
          access_token: getUserData().auth.access_token,
        };

        this.consultaMinhasNotificacoes();
        this.connect();
      }
    }
  },
  methods: {
    connect() {
      SocketIO.auth = this.userLogged;
      this.$store.dispatch(
        "notifications/setUserId",
        this.userLogged.id_usuario
      );
      SocketIO.connect();
    },
    disconnect() {
      SocketIO.off(this.SocketIOConnectError);
    },
    consultaMinhasNotificacoes() {
      this.$http.get(this.$api.MinhasNotificacoesPendentes).then((res) => {
        this.$store.dispatch(
          "notifications/setItens",
          res.status == 204 ? [] : res.data
        );
      });
    },
    layoutIsNotCora(){
      return window.location.pathname !== '/cora';
    },
  },
  created() {
    SocketIO.on(this.SocketIOPrivateMessage, (objeto) => {
      if(objeto.content === 'Logout'){
        setAbility()
      }else{
        this.consultaMinhasNotificacoes()
      }
    });
  },
  destroyed() {
    this.disconnect();
  },
};
</script>
